import { IAccountsLimitedBE, IBankruptcyEstateBE, ICaseBE, ICaseContactBE, ICaseDisciplineBE, ICaseTabCountBE, ICaseUserRightsBE, IDocumentLimitedBE, IHourlyRateBE } from "@datalex-software-as/datalex-client";


export class CaseBE {
  public readonly Id!: string;

  private InvoiceTitleLine1!: string;

  public setInvoiceTitleLine1(value: string) {
    this.InvoiceTitleLine1 = value;
    this.isChanged = this.isEqual('InvoiceTitleLine1')
  }

  public getInvoiceTitleLine1() {
    return this.InvoiceTitleLine1;
  }

  private InvoiceTitleLine2!: string;

  public setInvoiceTitleLine2(value: string) {
    this.InvoiceTitleLine2 = value;
    this.isChanged = this.isEqual('InvoiceTitleLine2')

  }

  public getInvoiceTitleLine2() {
    return this.InvoiceTitleLine2;
  }

  private InvoiceAttention!: string;

  public setInvoiceAttention(value: string) {
    this.InvoiceAttention = value;
    this.isChanged = this.isEqual('InvoiceAttention')

  }

  public getInvoiceAttention() {
    return this.InvoiceAttention;
  }

  private Number!: number;
  public setNumber(value: number) {
    this.Number = value;
    this.isChanged = this.isEqual('Number')

  }

  public getNumber() {
    return this.Number;
  }

  private Title!: string;
  public setTitle(value: string) {
    this.Title = value;
    this.isChanged = this.isEqual('Title')

  }

  public getTitle() {
    return this.Title;
  }

  private Description!: string;

  public setDescription(value: string) {
    this.Description = value;
    this.isChanged = this.isEqual('Description')

  }

  public getDescription() {
    return this.Description;
  }

  private Keywords!: string;

  public setKeywords(value: string) {
    this.Keywords = value;
    this.isChanged = this.isEqual('Keywords')

  }

  public getKeywords() {
    return this.Keywords;
  }

  private DepartmentId!: string | null;

  public setDepartmentId(value: string | null) {
    this.DepartmentId = value;
    this.isChanged = this.isEqual('DepartmentId')

  }

  public getDepartmentId() {
    return this.DepartmentId;
  }

  private HourRegistrationMaingroupId!: string | null;

  public setHourRegistrationMaingroupId(value: string | null) {
    this.HourRegistrationMaingroupId = value;
    this.isChanged = this.isEqual('HourRegistrationMaingroupId')

  }

  public getHourRegistrationMaingroupId() {
    return this.HourRegistrationMaingroupId;
  }

  private RoundVAT!: boolean | null;
  public setRoundVAT(value: boolean | null) {
    this.RoundVAT = value;
    this.isChanged = this.isEqual('RoundVAT')

  }

  public getRoundVAT() {
    return this.RoundVAT;
  }

  private RoundHourRegistrations!: boolean | null;
  public setRoundHourRegistrations(value: boolean | null) {
    this.RoundHourRegistrations = value;
    this.isChanged = this.isEqual('RoundHourRegistrations')

  }

  public getRoundHourRegistrations() {
    return this.RoundHourRegistrations;
  }

  private RoundCostRegistrations!: boolean | null;

  public setRoundCostRegistrations(value: boolean | null) {
    this.RoundCostRegistrations = value;
    this.isChanged = this.isEqual('RoundCostRegistrations')

  }

  public getRoundCostRegistrations() {
    return this.RoundCostRegistrations;
  }

  private AccountId!: string;

  public setAccountId(value: string) {
    this.AccountId = value;
    this.isChanged = this.isEqual('AccountId')

  }

  public getAccountId() {
    return this.AccountId;
  }

  private AccountName!: string;

  public setAccountName(value: string) {
    this.AccountName = value;
    this.isChanged = this.isEqual('AccountName')

  }

  public getAccountName() {
    return this.AccountName;
  }

  private SecondAccountsId!: string | null;

  public setSecondAccountsId(value: string | null) {
    this.SecondAccountsId = value;
    this.isChanged = this.isEqual('SecondAccountsId')

  }

  public getSecondAccountsId() {
    return this.SecondAccountsId;
  }

  private SecondAccountsItem!: IAccountsLimitedBE;
  public setSecondAccountsItem(value: IAccountsLimitedBE) {
    this.SecondAccountsItem = value;
    this.isChanged = this.isEqual('SecondAccountsItem')

  }

  public getSecondAccountsItem() {
    return this.SecondAccountsItem;
  }

  private StartedDate!: string;

  public setStartedDate(value: string) {
    this.StartedDate = value;
    this.isChanged = this.isEqual('StartedDate')

  }

  public getStartedDate() {
    return this.StartedDate;
  }

  private ClosedDate!: string | null;

  public setClosedDate(value: string | null) {
    this.ClosedDate = value;
    this.isChanged = this.isEqual('ClosedDate')

  }

  public getClosedDate() {
    return this.ClosedDate;
  }

  private HourlyRate!: number;

  public setHourlyRate(value: number) {
    if (!value) value = 0;
    this.HourlyRate = value;
    this.isChanged = this.isEqual('HourlyRate')

  }

  public getHourlyRate() {
    return this.HourlyRate;
  }

  private ArchiveNumber!: number | null;

  public setArchiveNumber(value: number | null) {
    this.ArchiveNumber = value;
    this.isChanged = this.isEqual('ArchiveNumber')

  }

  public getArchiveNumber() {
    return this.ArchiveNumber;
  }

  private ArchiveName!: string;

  public setArchiveName(value: string) {
    this.ArchiveName = value;
    this.isChanged = this.isEqual('ArchiveName')

  }

  public getArchiveName() {
    return this.ArchiveName;
  }

  private Budget!: number | null;

  public setBudget(value: number | null) {
    this.Budget = value;
    this.isChanged = this.isEqual('Budget')

  }

  public getBudget() {
    return this.Budget;
  }

  private Reference!: string;

  public setReference(value: string) {
    this.Reference = value;
    this.isChanged = this.isEqual('Reference')

  }

  public getReference() {
    return this.Reference;
  }

  private IsInternal!: boolean | null;

  public setIsInternal(value: boolean | null) {
    this.IsInternal = value;
    this.isChanged = this.isEqual('IsInternal')

  }

  public getIsInternal() {
    return this.IsInternal;
  }

  private TotalCharged!: number | null;

  public setTotalCharged(value: number | null) {
    this.TotalCharged = value;
    this.isChanged = this.isEqual('TotalCharged')

  }

  public getTotalCharged() {
    return this.TotalCharged;
  }

  private TotalChargedNotInvoiceable!: number | null;

  public setTotalChargedNotInvoiceable(value: number | null) {
    this.TotalChargedNotInvoiceable = value;
    this.isChanged = this.isEqual('TotalChargedNotInvoiceable')

  }

  public getTotalChargedNotInvoiceable() {
    return this.TotalChargedNotInvoiceable;
  }

  private TotalChargedWithoutNotInvoiceable!: number | null;

  public setTotalChargedWithoutNotInvoiceable(value: number | null) {
    this.TotalChargedWithoutNotInvoiceable = value;
    this.isChanged = this.isEqual('TotalChargedWithoutNotInvoiceable')

  }

  public getTotalChargedWithoutNotInvoiceable() {
    return this.TotalChargedWithoutNotInvoiceable;
  }

  private NotInvoicedHourAmount!: number;

  public setNotInvoicedHourAmount(value: number) {
    this.NotInvoicedHourAmount = value;
    this.isChanged = this.isEqual('NotInvoicedHourAmount')

  }

  public getNotInvoicedHourAmount() {
    return this.NotInvoicedHourAmount;
  }

  private NotInvoicedCostAmount!: number;

  public setNotInvoicedCostAmount(value: number) {
    this.NotInvoicedCostAmount = value;
    this.isChanged = this.isEqual('NotInvoicedCostAmount')

  }

  public getNotInvoicedCostAmount() {
    return this.NotInvoicedCostAmount;
  }

  private NotInvoicedOutlayAmount!: number;

  public setNotInvoicedOutlayAmount(value: number) {
    this.NotInvoicedOutlayAmount = value;
    this.isChanged = this.isEqual('NotInvoicedOutlayAmount')

  }

  public getNotInvoicedOutlayAmount() {
    return this.NotInvoicedOutlayAmount;
  }

  private CaseStatusTypeId!: string;

  public setCaseStatusTypeId(value: string) {
    this.CaseStatusTypeId = value;
    this.isChanged = this.isEqual('CaseStatusTypeId')

  }

  public getCaseStatusTypeId() {
    return this.CaseStatusTypeId;
  }

  private CaseStatusTypeDescription!: string;

  public setCaseStatusTypeDescription(value: string) {
    this.CaseStatusTypeDescription = value;
    this.isChanged = this.isEqual('IsBankruptcyEstate')

  }

  public getCaseStatusTypeDescription() {
    return this.CaseStatusTypeDescription;
  }

  private CaseContacts!: ICaseContactBE[];


  public setCaseContacts(value: ICaseContactBE[]) {
    this.CaseContacts = value.sort((a, b) => {
      return (a.IsDeleted === b.IsDeleted) ? 0 : a.IsDeleted ? -1 : 1;

    });
    this.isChanged = this.isEqual('CaseContacts')

  }

  public getCaseContacts() {
    return this.CaseContacts;
  }

  private CaseDisciplines!: ICaseDisciplineBE[];

  public setCaseDisciplines(value: ICaseDisciplineBE[]) {
    this.CaseDisciplines = value;
    this.isChanged = this.isEqual('CaseDisciplines')

  }

  public getCaseDisciplines() {
    return this.CaseDisciplines;
  }

  private CaseDocuments!: IDocumentLimitedBE[];

  public setCaseDocuments(value: IDocumentLimitedBE[]) {
    this.CaseDocuments = value;
    this.isChanged = this.isEqual('CaseDocuments')

  }

  public getCaseDocuments() {
    return this.CaseDocuments;
  }

  private CaseUserRights!: ICaseUserRightsBE[];

  public setCaseUserRights(value: ICaseUserRightsBE[]) {
    this.CaseUserRights = value;
    this.isChanged = this.isEqual('CaseUserRights')

  }

  public getCaseUserRights() {
    return this.CaseUserRights;
  }

  private CaseCounters!: ICaseTabCountBE;

  public setCaseCounters(value: ICaseTabCountBE) {
    this.CaseCounters = value;
    this.isChanged = this.isEqual('CaseCounters')

  }

  public getCaseCounters() {
    return this.CaseCounters;
  }

  private Timestamp!: number[];

  public setTimestamp(value: number[]) {
    this.Timestamp = value;
    this.isChanged = this.isEqual('Timestamp')

  }

  public getTimestamp() {
    return this.Timestamp;
  }

  private UserHourlyRates!: IHourlyRateBE[];

  public setUserHourlyRates(value: IHourlyRateBE[]) {
    this.UserHourlyRates = value;
    this.isChanged = this.isEqual('UserHourlyRates')

  }

  public getUserHourlyRates() {
    return this.UserHourlyRates;
  }

  private IsBankruptcyEstate!: boolean;

  public setIsBankruptcyEstate(value: boolean) {
    this.IsBankruptcyEstate = value;
    this.isChanged = this.isEqual('IsBankruptcyEstate')

  }

  public getIsBankruptcyEstate() {
    return this.IsBankruptcyEstate;
  }

  private BankruptcyEstateItem!: IBankruptcyEstateBE;

  public setBankruptcyEstateItem(value: IBankruptcyEstateBE) {
    this.BankruptcyEstateItem = value;
    this.isChanged = this.isEqual('BankruptcyEstateItem')

  }

  public getBankruptcyEstateItem() {
    return this.BankruptcyEstateItem;
  }

  private OmkOpgTitle1!: string;

  public setOmkOpgTitle1(value: string) {
    this.OmkOpgTitle1 = value;
    this.isChanged = this.isEqual('OmkOpgTitle1')

  }

  public getOmkOpgTitle1() {
    return this.OmkOpgTitle1;
  }

  private OmkOpgTitle2!: string;

  public setOmkOpgTitle2(value: string) {
    this.OmkOpgTitle2 = value;
    this.isChanged = this.isEqual('OmkOpgTitle2')

  }

  public getOmkOpgTitle2() {
    return this.OmkOpgTitle2;
  }

  private OmkOpgTitle3!: string;

  public setOmkOpgTitle3(value: string) {
    this.OmkOpgTitle3 = value;
    this.isChanged = this.isEqual('OmkOpgTitle3')

  }

  public getOmkOpgTitle3() {
    return this.OmkOpgTitle3;
  }

  private OmkOpgDate1!: string | null;

  public setOmkOpgDate1(value: string | null) {
    this.OmkOpgDate1 = value;
    this.isChanged = this.isEqual('OmkOpgDate1')

  }

  public getOmkOpgDate1() {
    return this.OmkOpgDate1;
  }

  private OmkOpgDate2!: string | null;

  public setOmkOpgDate2(value: string | null) {
    this.OmkOpgDate2 = value;
    this.isChanged = this.isEqual('OmkOpgDate2')

  }

  public getOmkOpgDate2() {
    return this.OmkOpgDate2;
  }

  private OmkOpgDate3!: string | null;

  public setOmkOpgDate3(value: string | null) {
    this.OmkOpgDate3 = value;
    this.isChanged = this.isEqual('OmkOpgDate3')

  }

  public getOmkOpgDate3() {
    return this.OmkOpgDate3;
  }

  private OrderConfirmationDialog!: boolean | null;

  public setOrderConfirmationDialog(value: boolean | null) {
    this.OrderConfirmationDialog = value;
    this.isChanged = this.isEqual('OrderConfirmationDialog')

  }

  public getOrderConfirmationDialog() {
    return this.OrderConfirmationDialog;
  }

  private IsPropertyAssignment!: boolean;

  public setIsPropertyAssignment(value: boolean) {
    this.IsPropertyAssignment = value;
    this.isChanged = this.isEqual('IsPropertyAssignment')

  }

  public getIsPropertyAssignment() {
    return this.IsPropertyAssignment;
  }

  private PropertyAssignmentNumber!: number | null;

  public setPropertyAssignmentNumber(value: number | null) {
    this.PropertyAssignmentNumber = value;
    this.isChanged = this.isEqual('PropertyAssignmentNumber')

  }

  public getPropertyAssignmentNumber() {
    return this.PropertyAssignmentNumber;
  }

  private DeleteDate!: string | null;

  public setDeleteDate(value: string | null) {
    this.DeleteDate = value;
    this.isChanged = this.isEqual('DeleteDate')

  }

  public getDeleteDate() {
    return this.DeleteDate;
  }

  private IsPhysicalDocumentsDeleted!: boolean | null;

  public setIsPhysicalDocumentsDeleted(value: boolean | null) {
    this.IsPhysicalDocumentsDeleted = value;
    this.isChanged = this.isEqual('IsPhysicalDocumentsDeleted')

  }

  public getIsPhysicalDocumentsDeleted() {
    return this.IsPhysicalDocumentsDeleted;
  }

  private MonthlyInvoice!: boolean;

  public setMonthlyInvoice(value: boolean) {
    this.MonthlyInvoice = value;
    this.isChanged = this.isEqual('MonthlyInvoice')

  }

  public getMonthlyInvoice() {
    return this.MonthlyInvoice;
  }

  private ReadyForInvoicing!: boolean;

  public setReadyForInvoicing(value: boolean) {
    this.ReadyForInvoicing = value;
    this.isChanged = this.isEqual('ReadyForInvoicing')

  }

  public getReadyForInvoicing() {
    return this.ReadyForInvoicing;
  }

  private EmailRef!: string;

  public setEmailRef(value: string) {
    this.EmailRef = value;
    this.isChanged = this.isEqual('EmailRef')

  }

  public getEmailRef() {
    return this.EmailRef;
  }

  private CaseCategoriesOnly!: boolean | null;

  public setCaseCategoriesOnly(value: boolean | null) {
    this.CaseCategoriesOnly = value;
    this.isChanged = this.isEqual('CaseCategoriesOnly')

  }

  public getCaseCategoriesOnly() {
    return this.CaseCategoriesOnly;
  }

  private CaseCategoriesOnlyLocked!: boolean | null;

  public setCaseCategoriesOnlyLocked(value: boolean | null) {
    this.CaseCategoriesOnlyLocked = value;
    this.isChanged = this.isEqual('CaseCategoriesOnlyLocked')

  }

  public getCaseCategoriesOnlyLocked() {
    return this.CaseCategoriesOnlyLocked;
  }

  private IsNew!: boolean;

  public setIsNew(value: boolean) {
    this.IsNew = value;
    this.isChanged = this.isEqual('IsNew')

  }

  public getIsNew() {
    return this.IsNew;
  }

  private IsDeleted!: boolean;

  public setIsDeleted(value: boolean) {
    this.IsDeleted = value;
    this.isChanged = this.isEqual('IsDeleted')

  }

  public getIsDeleted() {
    return this.IsDeleted;
  }

  private OriginCode: string | null;

  public setOriginCode(value: string | null) {
    this.OriginCode = value;
    this.isChanged = this.isEqual('OriginCode')

  }

  public getOriginCode() {
    return this.OriginCode;
  }


  private AccountsNumber!: any

  public setAccountsNumber(value: any) {
    this.AccountsNumber = value; // Add missing property
  }

  public getAccountsNumber() {
    return this.AccountsNumber;
  }

  private HourRegistrationMaingroupCode!: any

  public setHourRegistrationMaingroupCode(value: any) {
    this.HourRegistrationMaingroupCode = value; // Add missing property
  }

  public getHourRegistrationMaingroupCode() {
    return this.HourRegistrationMaingroupCode;
  }

  private HourRegistrationMaingroupName!: any

  public setHourRegistrationMaingroupName(value: any) {
    this.HourRegistrationMaingroupName = value; // Add missing property
  }

  public getHourRegistrationMaingroupName() {
    return this.HourRegistrationMaingroupName;
  }

  private SecondAccountsNumber!: any

  public setSecondAccountsNumber(value: any) {
    this.SecondAccountsNumber = value; // Add missing property
  }

  public getSecondAccountsNumber() {
    return this.SecondAccountsNumber;
  }

  private DepartmentName!: any

  public setDepartmentName(value: any) {
    this.DepartmentName = value; // Add missing property
  }

  public getDepartmentName() {
    return this.DepartmentName;
  }
  private DepartmentCode!: any

  public setDepartmentCode(value: any) {
    this.DepartmentCode = value; // Add missing property
  }

  public getDepartmentCode() {
    return this.DepartmentCode;
  }
  private OriginalNumber!: any

  public setOriginalNumber(value: any) {
    this.OriginalNumber = value; // Add missing property
  }

  public getOriginalNumber() {
    return this.OriginalNumber;
  }
  private OriginalId!: any

  public setOriginalId(value: any) {
    this.OriginalId = value; // Add missing property
  }

  public getOriginalId() {
    return this.OriginalId;
  }

  private CaseStatuses!: any[] | null

  public setCaseStatuses(value: any[] | null) {
    this.CaseStatuses = value; // Add missing property
  }

  public getCaseStatuses() {
    return this.CaseStatuses;
  }

  private CustomerDueDiligences!: any[] | null

  public setCustomerDueDiligences(value: any[] | null) {
    this.CustomerDueDiligences = value; // Add missing property
  }

  public getCustomerDueDiligences() {
    return this.CustomerDueDiligences;
  }




  private origin!: ICaseBE;
  public getOrigin() {
    return this.origin;
  }

  public isChanged: boolean = false;

  public case!: ICaseBE;

  public getCase(): ICaseBE {
    return {
      Id: this.Id,
      InvoiceTitleLine1: this.InvoiceTitleLine1,
      InvoiceTitleLine2: this.InvoiceTitleLine2,
      InvoiceAttention: this.InvoiceAttention,
      Number: this.Number,
      Title: this.Title,
      Description: this.Description,
      Keywords: this.Keywords,
      DepartmentId: this.DepartmentId,
      HourRegistrationMaingroupId: this.HourRegistrationMaingroupId,
      RoundVAT: this.RoundVAT,
      RoundHourRegistrations: this.RoundHourRegistrations,
      RoundCostRegistrations: this.RoundCostRegistrations,
      AccountId: this.AccountId,
      AccountName: this.AccountName,
      SecondAccountsId: this.SecondAccountsId,
      SecondAccountsItem: this.SecondAccountsItem,
      StartedDate: this.StartedDate,
      ClosedDate: this.ClosedDate,
      HourlyRate: this.HourlyRate,
      ArchiveNumber: this.ArchiveNumber,
      ArchiveName: this.ArchiveName,
      Budget: this.Budget,
      Reference: this.Reference,
      IsInternal: this.IsInternal,
      TotalCharged: this.TotalCharged,
      TotalChargedNotInvoiceable: this.TotalChargedNotInvoiceable,
      TotalChargedWithoutNotInvoiceable: this.TotalChargedWithoutNotInvoiceable,
      NotInvoicedHourAmount: this.NotInvoicedHourAmount,
      NotInvoicedCostAmount: this.NotInvoicedCostAmount,
      NotInvoicedOutlayAmount: this.NotInvoicedOutlayAmount,
      CaseStatusTypeId: this.CaseStatusTypeId,
      CaseStatusTypeDescription: this.CaseStatusTypeDescription,
      CaseContacts: this.CaseContacts,
      CaseDisciplines: this.CaseDisciplines,
      CaseDocuments: this.CaseDocuments,
      CaseUserRights: this.CaseUserRights,
      CaseCounters: this.CaseCounters,
      Timestamp: this.Timestamp,
      UserHourlyRates: this.UserHourlyRates,
      IsBankruptcyEstate: this.IsBankruptcyEstate,
      BankruptcyEstateItem: this.BankruptcyEstateItem,
      OmkOpgTitle1: this.OmkOpgTitle1,
      OmkOpgTitle2: this.OmkOpgTitle2,
      OmkOpgTitle3: this.OmkOpgTitle3,
      OmkOpgDate1: this.OmkOpgDate1,
      OmkOpgDate2: this.OmkOpgDate2,
      OmkOpgDate3: this.OmkOpgDate3,
      OrderConfirmationDialog: this.OrderConfirmationDialog,
      IsPropertyAssignment: this.IsPropertyAssignment,
      PropertyAssignmentNumber: this.PropertyAssignmentNumber,
      DeleteDate: this.DeleteDate,
      IsPhysicalDocumentsDeleted: this.IsPhysicalDocumentsDeleted,
      MonthlyInvoice: this.MonthlyInvoice,
      ReadyForInvoicing: this.ReadyForInvoicing,
      EmailRef: this.EmailRef,
      CaseCategoriesOnly: this.CaseCategoriesOnly,
      CaseCategoriesOnlyLocked: this.CaseCategoriesOnlyLocked,
      IsNew: this.IsNew,
      IsDeleted: this.IsDeleted,
      AccountsNumber: this.AccountsNumber,
      HourRegistrationMaingroupCode: this.HourRegistrationMaingroupCode,
      HourRegistrationMaingroupName: this.HourRegistrationMaingroupName,
      SecondAccountsNumber: this.SecondAccountsNumber,
      DepartmentName: this.DepartmentName,
      DepartmentCode: this.DepartmentCode,
      OriginalNumber: this.OriginalNumber,
      OriginalId: this.OriginalId,
      OriginCode: this.OriginCode,
      CaseStatuses: this.CaseStatuses,
      CustomerDueDiligences: this.CustomerDueDiligences
    }
  }


  constructor(data: ICaseBE) {
    // this.origin = Object.freeze(data);
    this.origin = Object.freeze(JSON.parse(JSON.stringify(data)));
    this.Id = data.Id;
    this.InvoiceTitleLine1 = data.InvoiceTitleLine1;
    this.InvoiceTitleLine2 = data.InvoiceTitleLine2;
    this.InvoiceAttention = data.InvoiceAttention;
    this.Number = data.Number;
    this.Title = data.Title;
    this.Description = data.Description;
    this.Keywords = data.Keywords;
    this.DepartmentId = data.DepartmentId;
    this.HourRegistrationMaingroupId = data.HourRegistrationMaingroupId;
    this.RoundVAT = data.RoundVAT;
    this.RoundHourRegistrations = data.RoundHourRegistrations;
    this.RoundCostRegistrations = data.RoundCostRegistrations;
    this.AccountId = data.AccountId;
    this.AccountName = data.AccountName;
    this.SecondAccountsId = data.SecondAccountsId;
    this.SecondAccountsItem = data.SecondAccountsItem;
    this.StartedDate = data.StartedDate;
    this.ClosedDate = data.ClosedDate;
    this.HourlyRate = data.HourlyRate;
    this.ArchiveNumber = data.ArchiveNumber;
    this.ArchiveName = data.ArchiveName;
    this.Budget = data.Budget;
    this.Reference = data.Reference;
    this.IsInternal = data.IsInternal;
    this.TotalCharged = data.TotalCharged;
    this.TotalChargedNotInvoiceable = data.TotalChargedNotInvoiceable;
    this.TotalChargedWithoutNotInvoiceable = data.TotalChargedWithoutNotInvoiceable;
    this.NotInvoicedHourAmount = data.NotInvoicedHourAmount;
    this.NotInvoicedCostAmount = data.NotInvoicedCostAmount;
    this.NotInvoicedOutlayAmount = data.NotInvoicedOutlayAmount;
    this.CaseStatusTypeId = data.CaseStatusTypeId;
    this.CaseStatusTypeDescription = data.CaseStatusTypeDescription;
    this.CaseContacts = data.CaseContacts;
    this.CaseDisciplines = data.CaseDisciplines;
    this.CaseDocuments = data.CaseDocuments;
    this.CaseUserRights = data.CaseUserRights;
    this.CaseCounters = data.CaseCounters;
    this.Timestamp = data.Timestamp;
    this.UserHourlyRates = data.UserHourlyRates;
    this.IsBankruptcyEstate = data.IsBankruptcyEstate;
    this.BankruptcyEstateItem = data.BankruptcyEstateItem;
    this.OmkOpgTitle1 = data.OmkOpgTitle1;
    this.OmkOpgTitle2 = data.OmkOpgTitle2;
    this.OmkOpgTitle3 = data.OmkOpgTitle3;
    this.OmkOpgDate1 = data.OmkOpgDate1;
    this.OmkOpgDate2 = data.OmkOpgDate2;
    this.OmkOpgDate3 = data.OmkOpgDate3;
    this.OrderConfirmationDialog = data.OrderConfirmationDialog;
    this.IsPropertyAssignment = data.IsPropertyAssignment;
    this.PropertyAssignmentNumber = data.PropertyAssignmentNumber;
    this.DeleteDate = data.DeleteDate;
    this.IsPhysicalDocumentsDeleted = data.IsPhysicalDocumentsDeleted;
    this.MonthlyInvoice = data.MonthlyInvoice;
    this.ReadyForInvoicing = data.ReadyForInvoicing;
    this.EmailRef = data.EmailRef;
    this.CaseCategoriesOnly = data.CaseCategoriesOnly;
    this.CaseCategoriesOnlyLocked = data.CaseCategoriesOnlyLocked;
    this.IsNew = data.IsNew;
    this.IsDeleted = data.IsDeleted;
    this.AccountsNumber = data.AccountsNumber,
      this.HourRegistrationMaingroupCode = data.HourRegistrationMaingroupCode,
      this.HourRegistrationMaingroupName = data.HourRegistrationMaingroupName,
      this.SecondAccountsNumber = data.SecondAccountsNumber,
      this.DepartmentName = data.DepartmentName,
      this.DepartmentCode = data.DepartmentCode,
      this.OriginalNumber = data.OriginalNumber,
      this.OriginalId = data.OriginalId,
      this.OriginCode = data.OriginCode,
      this.CaseStatuses = data.CaseStatuses,
      this.CustomerDueDiligences = data.CustomerDueDiligences
  }


  private isEqual(key: string) {
    //@ts-ignore
    let oldValue = this.origin[key];
    //@ts-ignore
    let newValue = this[key];
    //console.table({ key, oldValue, newValue});

    if (typeof (newValue) === 'number') {
      return !(newValue === 0 && oldValue === null)
    }

    if (typeof (oldValue) === 'number') {
      return !(Number(oldValue) === Number(newValue))
    }

    if (key.includes("Id")) {
      return oldValue.toUpperCase() === newValue.toUpperCase();
    }

    if (typeof (oldValue) === 'string' && typeof (newValue) === 'string') {

      return oldValue.toUpperCase() !== newValue.toUpperCase();
    }
    const oldBase64 = btoa(JSON.stringify(oldValue));
    const newBase64 = btoa(JSON.stringify(newValue));

    return !(oldBase64 === newBase64);
  }

}



