import { Component, Input, OnInit } from '@angular/core';
import { IgxIconComponent } from '@infragistics/igniteui-angular';
import { NgStyle } from '@angular/common';


export interface CtxItem {
  rightIcon?: string,
  leftIcon?: string,
  disabled?: boolean,
  disabledReason?: string,
  label: string,
  action: (arg?: any) => any,
  actionParams?: {},
  subItems?: CtxItem[]
}

@Component({
  selector: 'grid-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
  standalone: true,
  imports: [NgStyle, IgxIconComponent]
})

export class ContextMenuComponent implements OnInit {

  ngOnInit() {

  }
  @Input() public left = 0;
  @Input() public right = 0;
  @Input() public subLeft = 0;
  @Input() public x = 0;
  @Input() public y = 0;
  @Input() public content!: CtxItem[][];
  // @Input() public show: boolean = false;
}