import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { CaseBE } from 'src/app/classes/CaseBE';
import { DatalexClient, IHourRegistrationBE, IHourRegistrationMaingroupBE, IHourRegistrationTypeBE, IInvoiceTypeBE, IRoleTypeBE, IUserLimitedBE } from '@datalex-software-as/datalex-client';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SystemCacheService } from 'src/app/services/system-cache.service';
import { formatDate } from 'src/app/util/FormatDate';
import { HourRegistrationFilterProvider } from 'src/app/util/HourRegistrationFilters';
import { toLocalIsoString } from 'src/app/util/DatalexDateTime';
import { LangEnum } from 'src/app/util/LanguageEnum';
import { InvoiceTypeCode } from 'src/app/util/InvoiceTypeEnum';
import { AbsoluteScrollStrategy, ConnectedPositioningStrategy, IComboFilteringOptions, IgxGridComponent, IgxNumberSummaryOperand, IgxSummaryResult, OverlaySettings, SortingDirection, IgxLabelDirective, IgxSimpleComboComponent, IgxComboItemDirective, IgxComboEmptyDirective, IgxComboHeaderItemDirective, IgxInputGroupComponent, IgxInputDirective, IgxDatePickerComponent, IgxIconComponent, IgxPrefixDirective, IgxSuffixDirective, IgxButtonDirective, IgxRippleDirective, IgxColumnComponent, IgxCellTemplateDirective, IgxFilterCellTemplateDirective, IgxDialogModule, IgxDialogComponent, IRowSelectionEventArgs } from '@infragistics/igniteui-angular';
import { ComboSelectFunctionsService } from 'src/app/services/combo-select-functions.service';
import { firstValueFrom, Subscription } from 'rxjs';
import { ScreenSizeService } from 'src/app/services/screen-size.service';
import { NorwegianNumberFormatPipe } from '../../../../../util/pipes/norwegian-number-format.pipe';
import { GridFilterInputComponent } from '../../../../UI/grid-filter-input/grid-filter-input.component';
import { GridItemCountComponent } from '../../../../UI/grid-item-count/grid-item-count.component';
import { GridFooterCollectionComponent } from '../../../../UI/grid-footer-collection/grid-footer-collection.component';
import { NgStyle, NgIf, CommonModule } from '@angular/common';
import { DeviceService } from 'src/app/services/device.service';
import { replaceNewlinesForCSharp } from 'src/app/util/JStoCSharpUtil';
import { SystemDialogEnum } from 'src/app/util/SystemDialogEnum';
import { UserRightsProviderService } from 'src/app/services/user-rights-provider.service';
import { Router, ActivatedRoute } from '@angular/router';

import { EventEmitterService, IForcedEventTypeEnum } from 'src/app/services/event-emitter.service';
import { UserAreaEnum, UserRightTypeEnum } from 'src/app/util/UserRightUtil';
import { RoleCode, RoleTypeCategoryIdEnum, RoleTypes } from 'src/app/util/RoleTypeEnum';
import newActivity from 'src/app/classes/Activity/Actvity';
import { ActivityLogService } from 'src/app/components/UI/activity-log-overlay/activity-log.service';

export interface IFormInterface {
  CompletedBy: FormControl<string>;
  HourRegistrationTypeId: FormControl<string>;
  Description: FormControl<string>;
  CompletedDate: FormControl<string>;
  Hours: FormControl<number | null>;
  Minutes: FormControl<number | null>;
  WorkedMinuttes: FormControl<number | null>;
  Rate: FormControl<number | null>;
  Fee: FormControl<number | null>;
  InvoiceTypeId: FormControl<string>;
  IsNew: FormControl<boolean>;
  HourRegistrationTypeIsRateVariable: FormControl<boolean | null>;
  OriginCode: FormControl<string>;
  HourRegistrationTypeInvoiceTypeId: FormControl<string>,
  HourRegistrationTypeMaingroupId: FormControl<string | null>,
  HourRegistrationTypeName: FormControl<string>,
  HourRegistrationTypeRate: FormControl<number | null>,
}

interface IUserExtended extends IUserLimitedBE {
  filterKey: string
}
interface IHourTypeExtended extends IHourRegistrationTypeBE {
  mainTypeName: string,
  filterKey: string
}
interface IInvoiceTypeExtended extends IInvoiceTypeBE {
  filterKey: string
}

export interface IItemSelected {
  index: number,
  data: IHourRegistrationBE[]
}

class SumSummary {
  private formatNumberLocale(number: number, locale = 'no-NO') {
    return new Intl.NumberFormat(locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  }
  public operate(data?: any[]): IgxSummaryResult[] {
    const result = [];
    if (data) {
      let newData: number[] = [];
      data.forEach((row) => {
        newData.push(Number(row));
      });
      result.push({
        key: 'sum:',
        label: 'Sum:',
        summaryResult: this.formatNumberLocale(IgxNumberSummaryOperand.sum(data)),
      });
    }
    return result;
  }
}
class TimeSummary {

  private calculateHours(workedMinutes: number | null) {
    if (!workedMinutes) return 0;
    return Math.floor(workedMinutes / 60)
  }
  private caclulateMinutes(workedMinutes: number | null) {
    if (!workedMinutes) return 0;
    return workedMinutes % 60;
  }

  private deconstructWorkedMinutes(workedMinutes: number | null) {
    if (!workedMinutes) return "00:00";
    const hours = this.calculateHours(workedMinutes);
    const minutes = this.caclulateMinutes(workedMinutes);
    return `${hours}:${minutes < 10 ? `0${minutes}` : minutes}`
  }

  public operate(data?: any[]): IgxSummaryResult[] {
    const result = [];
    if (data) {
      let newData: number[] = [];
      data.forEach((row) => {
        newData.push(Number(row));
      });
      result.push({
        key: 'sum:',
        label: 'Sum:',
        summaryResult: this.deconstructWorkedMinutes(IgxNumberSummaryOperand.sum(data)),
      });
    }
    return result;
  }
}


@Component({
  selector: 'app-hours-registration',
  templateUrl: './hours-registration.component.html',
  styleUrls: ['./hours-registration.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, IgxDialogModule, ReactiveFormsModule, IgxLabelDirective, IgxSimpleComboComponent, IgxComboItemDirective, IgxComboEmptyDirective, IgxComboHeaderItemDirective, IgxInputGroupComponent, IgxInputDirective, IgxDatePickerComponent, IgxIconComponent, IgxPrefixDirective, IgxSuffixDirective, IgxButtonDirective, IgxRippleDirective, NgStyle, IgxGridComponent, NgIf, IgxColumnComponent, IgxCellTemplateDirective, GridFooterCollectionComponent, GridItemCountComponent, IgxFilterCellTemplateDirective, GridFilterInputComponent, NorwegianNumberFormatPipe]
})
export class HoursRegistrationComponent implements OnInit, OnDestroy {

  @ViewChild('roleDialog', { static: false }) roleDialog!: IgxDialogComponent;

  roleTypes: IRoleTypeBE[] = [];
  selectedRoleTypeId!: string;

  @Input() data!: CaseBE;
  @Input() rerenderTrigger!: number;

  @Input() forceLowerPermissions = false;

  @Output() itemSelected = new EventEmitter<IItemSelected>();

  @ViewChild("fInput") fInput!: ElementRef;
  @ViewChild("grid") grid!: IgxGridComponent;
  public sumSummary = SumSummary;
  public timeSummary = TimeSummary;

  public customOverlaySettings!: OverlaySettings;

  descriptionManuallyChanged = false;
  private descriptionInitialValue: string | null = null;

  date = new Date();
  employees!: IUserLimitedBE[];
  CompletedBy!: string;
  loggedInUser!: string;
  invoiceTypes!: IInvoiceTypeBE[];
  invoiceTypeId!: string;
  selectedHourRegistrationId = "";
  selectedHourRegistrationType!: IHourRegistrationTypeBE;
  hourRegistrationTypesDS!: IHourRegistrationTypeBE[];
  hourRegistrationTypes!: IHourRegistrationTypeBE[];
  activeMainGroups!: IHourRegistrationMaingroupBE[];
  hourRegistrationsList: IHourRegistrationBE[] = [];
  selectedHourRegistration: IHourRegistrationBE | null = null;
  rateLabel = "Sats:";

  typeMainGroupName = "";
  typeMainGroupNameVisible = false;
  emptyGridMessage: string = 'Ingen belastninger registret.';

  deleteButtonActive: boolean = false;
  cancelButtonActive: boolean = false;
  saveButtonActive: boolean = false;
  form: any;
  public selectedTypeMaingroupId!: string | null;
  public hourTypeFilterInput = new FormControl("");

  showOwnBillableHours: boolean = true;
  showOthersBillableHours: boolean = false;
  showAllNonBillable: boolean = false;
  showInvoicedStatus: boolean = false;

  includeShortlist: boolean = false;

  internCategoryId = "6034096e-1574-4571-8b9a-4c663a1af63e";


  hourRegistrationForm = new FormGroup<IFormInterface>({
    CompletedBy: new FormControl(),
    HourRegistrationTypeId: new FormControl(),
    Description: new FormControl(),
    CompletedDate: new FormControl(),
    Hours: new FormControl(),
    Minutes: new FormControl(),
    WorkedMinuttes: new FormControl(),
    Rate: new FormControl(),
    Fee: new FormControl(),
    InvoiceTypeId: new FormControl(),
    IsNew: new FormControl(),
    HourRegistrationTypeIsRateVariable: new FormControl(),
    OriginCode: new FormControl(),

    HourRegistrationTypeInvoiceTypeId: new FormControl(),
    HourRegistrationTypeMaingroupId: new FormControl(),
    HourRegistrationTypeName: new FormControl(),
    HourRegistrationTypeRate: new FormControl(),
  })
  NewHourRegistration!: IHourRegistrationBE;
  customOverlaySettings1!: { target: HTMLElement; positionStrategy: ConnectedPositioningStrategy; scrollStrategy: AbsoluteScrollStrategy; };
  //sortingDirection = SortingDirection;

  isMobile!: boolean;
  isTablet!: boolean;
  isDesktop!: boolean;
  private subscriptions = new Subscription();

  hasRightToEditHours: boolean = false;

  constructor(
    private dlxClient: DatalexClient,
    public sys: SystemCacheService,
    private hfp: HourRegistrationFilterProvider,
    public cdr: ChangeDetectorRef,
    public combo: ComboSelectFunctionsService,
    public screenSize: ScreenSizeService, public deviceService: DeviceService,
    public userRights: UserRightsProviderService,
    private router: Router, private route: ActivatedRoute, private eventEmitter: EventEmitterService,
    private als: ActivityLogService) {

    this.subscriptions.add(this.screenSize.isMobile$.subscribe(isMobile => {
      this.isMobile = isMobile;
    }));

    this.subscriptions.add(this.screenSize.isTablet$.subscribe(isTablet => {
      this.isTablet = isTablet;
    }));

    this.subscriptions.add(this.screenSize.isDesktop$.subscribe(isDesktop => {
      this.isDesktop = isDesktop;
    }));

  }

  ngOnInit(): void {

    try {
      this.initComponent();
    } catch {
      this.sys.isReady.subscribe({
        next: () => {
          this.initComponent();
        }
      })
    }

  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }


  // shouldUpdateDescription({ isNew, custom, desc }: { isNew: boolean, custom: boolean, desc: string | null }): boolean {
  //   if (!isNew) {
  //     return false; // Don't update if editing an existing registration
  //   } else if (custom) {
  //     return false; // Don't update if user entered a custom description
  //   } else {
  //     return true; // Update description in other cases
  //   }
  // }

  rolePermission = 0;


  initComponent() {
    if (this.forceLowerPermissions) {
      this.hasRightToEditHours = false;
      this.includeShortlist = true;
    } else {
      this.hasRightToEditHours = this.userRights.canEditHoursCosts();
      this.includeShortlist = this.router.url.includes('shortlist');
    }

    this.rolePermission = this.getRolePermissions();

    Object.keys(this.hourRegistrationForm.controls).forEach(control => {
      if (this.hasRightToEditHours) {
        this.hourRegistrationForm.get(control)!.enable();
      } else {
        this.hourRegistrationForm.get(control)!.disable();
      }
    });


    this.loggedInUser = this.sys.loggedInUser.ContactName;
    this.CompletedBy = this.sys.loggedInUser.Id;
    this.employees = this.sys.allEmployees;

    /* We create a new array EmployeesData so that the filter includes Shortnames  */

    let EmployeesData: IUserExtended[] = [];
    this.employees.forEach(employee => {
      let EmployeeExtended: IUserExtended = {
        ...employee,
        filterKey: `${employee.Shortform} ${employee.ContactName}`
      }
      EmployeesData.push(EmployeeExtended)
    })

    this.employees = EmployeesData;

    let InvoiceTypesData: IInvoiceTypeBE[] = [];
    this.invoiceTypes = this.sys.invoiceTypes;
    this.invoiceTypes.forEach(invoiceType => {
      let invoiceTypeExtended: IInvoiceTypeExtended = {
        ...invoiceType, filterKey: `${invoiceType.Code} ${invoiceType.Name}`
      }
      InvoiceTypesData.push(invoiceTypeExtended)
    })

    this.invoiceTypes = InvoiceTypesData;
    this.patchFormsDefaultValues();

    /** active HourRegistrationMaingroups  and Hour Registration Types  **/

    this.dlxClient.GetHourRegistrationMaingroups().subscribe({
      next: res => {
        this.activeMainGroups = res.filter(i => i.Active === true);
        const $o = this.activeMainGroups.findIndex(i => i.Code === "O");
        const O = this.activeMainGroups.splice($o, 1)[0]
        this.activeMainGroups.unshift(O)

        let typeData: IHourTypeExtended[] = []
        this.hourRegistrationTypesDS = this.sys.hourRegistrationMainGroups.filter(i => i.Active === true);

        /* mainTypeName: maingroup.Code === "O" ? " " + maingroup!.Name : maingroup!.Name, this line is to make Ordinær arbeid group appear first in the list*/
        this.hourRegistrationTypesDS.forEach((type, i) => {
          try {
            let maingroup = this.activeMainGroups.find(i => {
              return i.Id === type.HourRegistrationMaingroupId
            });
            if (maingroup) {
              let hourTypeExtended: IHourTypeExtended = {
                ...type,
                mainTypeName: maingroup.Code === "O" ? " " + maingroup!.Name : maingroup!.Name,
                filterKey: `${maingroup!.Name} ${type.Code} ${type.Name}`
              }
              typeData.push(hourTypeExtended)
            }
          } catch (error) {
            console.warn(error)
          }
          this.hourRegistrationTypes = typeData;
        })
      }
    })

    /*  */
    this.hourRegistrationForm.valueChanges.subscribe(() => {
      this.cancelButtonActive = true;
      this.saveButtonActive = (this.hourRegistrationForm.value.HourRegistrationTypeId && this.hourRegistrationForm.value.Description) ? true : false;
    })

    /* get HourRegistration type, description, InvoiceTypeId, rate and IsRateVariable */

    this.hourRegistrationForm.get('HourRegistrationTypeId')!.valueChanges.subscribe(value => {
      if (value && value.length === 36) {
        this.dlxClient.GetHourRegistrationType(value).subscribe({
          next: (response) => {
            const { InvoiceTypeId, HourRegistrationTypeDescriptions, IsRateVariable, HourRegistrationMaingroupId, Name, Rate } = response;

            this.invoiceTypeId = InvoiceTypeId;

            if (this.shouldUpdateDescription) {
              this.hourRegistrationForm.get('Description')?.patchValue(this.hfp.filterHourRegistrationDescriptionByLanguage(
                HourRegistrationTypeDescriptions,
                LangEnum.NO
              ).Description || Name);
            }

            this.hourRegistrationForm.patchValue({
              InvoiceTypeId: InvoiceTypeId,
              HourRegistrationTypeIsRateVariable: IsRateVariable,
              HourRegistrationTypeInvoiceTypeId: InvoiceTypeId,
              HourRegistrationTypeMaingroupId: HourRegistrationMaingroupId,
              HourRegistrationTypeName: Name,
              HourRegistrationTypeRate: Rate
            });

            this.rateLabel = IsRateVariable ? 'Sats:' : 'Sats: (fast)';

            this.dlxClient.GetRateForUserInCase(
              this.data.getCase(),
              this.sys.loggedInUser,
              response,
              this.data.getHourlyRate(),
              true,
              toLocalIsoString()
            ).subscribe({
              next: (Rate) => {
                if (!this.selectedHourRegistration) {
                  this.hourRegistrationForm.patchValue({ Rate: Rate });
                }
              }
            });
          }
        });
      }
    });

    this.hourRegistrationForm.get('Rate')!.valueChanges.subscribe(() => {
      this.hourRegistrationForm.patchValue({ Fee: 0 });
      this.patchFeeAndWorkedMinutes();
    })

    this.hourRegistrationForm.get('Hours')!.valueChanges.subscribe(() => {
      this.hourRegistrationForm.patchValue({ Fee: 0 });
      this.patchFeeAndWorkedMinutes();
    })

    this.hourRegistrationForm.get('Minutes')!.valueChanges.subscribe(() => {
      this.hourRegistrationForm.patchValue({ Fee: 0 })
      this.patchFeeAndWorkedMinutes();
    })

    /* Get grid hourRegistrations list */

    this.getHourRegistrationsList();

    /* NewHourRegistration */

    this.dlxClient.NewHourRegistration().subscribe({
      next: res => {
        this.NewHourRegistration = res;
      }
    })
  }


  patchFormsDefaultValues() {
    this.hourRegistrationForm.patchValue({ CompletedBy: this.CompletedBy, CompletedDate: formatDate(new Date()), InvoiceTypeId: this.invoiceTypes[0].Id });
  }

  handleRowSelection(event: IRowSelectionEventArgs): void {
    const selection = event.newSelection[0] as IHourRegistrationBE;
    this.shouldUpdateDescription = false;

    if (selection) {
      this.selectedHourRegistration = selection;
      this.selectedHourRegistrationId = selection.Id;

      this.hourRegistrationForm.patchValue({
        CompletedBy: selection.CompletedBy,
        HourRegistrationTypeId: selection.HourRegistrationTypeId,
        Description: replaceNewlinesForCSharp(selection.Description, true),
        CompletedDate: selection.CompletedDate,
        Hours: this.calculateHours(selection.WorkedMinuttes),
        Minutes: this.caclulateMinutes(selection.WorkedMinuttes),
        WorkedMinuttes: selection.WorkedMinuttes,
        Rate: selection.Rate,
        Fee: selection.Fee,
        InvoiceTypeId: selection.InvoiceTypeId,
        IsNew: selection.IsNew,
        HourRegistrationTypeIsRateVariable: selection.HourRegistrationTypeIsRateVariable,
        OriginCode: selection.OriginCode,
        HourRegistrationTypeInvoiceTypeId: selection.HourRegistrationTypeId,
        HourRegistrationTypeMaingroupId: selection.HourRegistrationTypeMaingroupId,
        HourRegistrationTypeName: selection.HourRegistrationTypeName,
        HourRegistrationTypeRate: selection.HourRegistrationTypeRate,
      })

      this.selectionIsInvoiced = this.setSelectionIsInvoiced(selection);

      this.deleteButtonActive = true;
      this.cancelButtonActive = true;



      const index = this.hourRegistrationsList.findIndex(i => i.Id === selection.Id);

      this.itemSelected.emit({ index: index, data: this.hourRegistrationsList });
    }
  }


  selectionIsInvoiced: boolean = false;

  setSelectionIsInvoiced(selection: IHourRegistrationBE | null) {
    if (!selection) return false;
    return selection.InvoiceTypeId.toUpperCase() === InvoiceTypeCode.F.toUpperCase()
  }

  public calculateTotalWorkedMinutes(): string {
    let totalMinutes: number = 0;

    this.hourRegistrationsList.forEach(item => {
      if (item.WorkedMinuttes !== null) {
        totalMinutes += item.WorkedMinuttes
      }
    });
    return this.deconstructWorkedMinutes(totalMinutes);
  }

  onDeleteHourRegistration() {
    if (confirm(SystemDialogEnum.GENERAL_DELETE_CONFIRMATION) === false) {
      this.resetHourRegistrationform();
      return;
    }
    this.dlxClient.DeleteHourRegistration(this.selectedHourRegistrationId).subscribe({
      next: (res) => {
        this.getHourRegistrationsList();
        this.deleteButtonActive = false;
      }
    })
    this.resetHourRegistrationform();
  }

  resetHourRegistrationform() {
    this.hourRegistrationForm.reset();
    this.patchFormsDefaultValues();
    this.selectedHourRegistration = null;
    this.cancelButtonActive = false;
    this.deleteButtonActive = false;
    this.saveButtonActive = false;
    this.selectionIsInvoiced = false;
    this.grid.selectRows([], true);
    this.shouldUpdateDescription = true;
  }

  calculateFee() {
    this.form.Hours ??= 0;
    this.form.Minutes ??= 0;
    return this.form.HourRegistrationTypeIsRateVariable ? parseFloat(((this.form.Hours + (this.form.Minutes / 60)) * this.form.Rate).toFixed(2)) : this.form.Rate;
  }

  patchFeeAndWorkedMinutes() {
    this.form = this.hourRegistrationForm.value;
    this.hourRegistrationForm.patchValue({
      WorkedMinuttes: this.calculateWorkedMinutes(),
      Fee: this.calculateFee(),
    })
  }

  calculateWorkedMinutes(): number {
    this.form.Hours ??= 0;
    this.form.Minutes ??= 0;
    return ((this.form.Hours * 60) + this.form.Minutes);
  }

  deconstructWorkedMinutes(workedMinutes: number | null) {
    if (workedMinutes === null || workedMinutes === 0) return "00:00";
    const hours = this.calculateHours(workedMinutes);
    const minutes = this.caclulateMinutes(workedMinutes);
    return `${hours}:${minutes < 10 ? `0${minutes}` : minutes}`
  }

  calculateHours(workedMinutes: number | null) {
    if (!workedMinutes) return 0;
    return Math.floor(workedMinutes / 60)
  }
  caclulateMinutes(workedMinutes: number | null) {
    if (!workedMinutes) return 0;
    return workedMinutes % 60;
  }

  public formatDateOptions = {
    format: 'dd.MM.yyyy',
  };

  public formatOptions = {
    digitsInfo: '.0',
    currencyCode: 'NOK',
    display: '',
  };

  formatNumberWithThousands(x: number) {

    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace(".", ",");
  }

  filterOptions: IComboFilteringOptions = {
    caseSensitive: false,
    filterable: true,
    filteringKey: "filterKey"
  }

  getHourRegistrationsList() {
    this.dlxClient.GetHourRegistrationByCaseIdAndUserId(this.data.Id, this.data.getAccountId(), null, true)
      .subscribe({
        next: (res) => {

          const hourRegistrations = res as IHourRegistrationBE[];
          const filteredRes: IHourRegistrationBE[] = hourRegistrations.filter(registration => {
            const isBillable = registration.InvoiceTypeId === InvoiceTypeCode.S || registration.InvoiceTypeId === InvoiceTypeCode.J;
            const isNonBillable = registration.InvoiceTypeId === InvoiceTypeCode.N;
            const isInvoiced = registration.InvoiceTypeId === InvoiceTypeCode.F;

            return (this.showOwnBillableHours && (registration.CompletedBy === this.sys.loggedInUser.Id) && isBillable) ||
              (this.showOthersBillableHours && (registration.CompletedBy !== this.sys.loggedInUser.Id) && isBillable) ||
              (this.showAllNonBillable && isNonBillable) ||
              (this.showInvoicedStatus && isInvoiced);
          });

          this.hourRegistrationsList = [...filteredRes];
        }
      });
  }

  incrementDate() {
    let currentDate = this.hourRegistrationForm.controls.CompletedDate.value || new Date();
    currentDate = new Date(currentDate);
    const nextDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
    this.hourRegistrationForm.controls.CompletedDate.setValue(formatDate(nextDate));
  }

  decrementDate() {
    let currentDate = this.hourRegistrationForm.controls.CompletedDate.value || new Date();
    currentDate = new Date(currentDate);
    const previousDate = new Date(currentDate.setDate(currentDate.getDate() - 1));
    this.hourRegistrationForm.controls.CompletedDate.setValue(formatDate(previousDate));
  }

  showEditingAlert(): void {
    if (!this.hasRightToEditHours) {
      alert("Du har ikke tillatelse til å registrere timer.");
    }
  }

  getUserContact() {
    return this.employees.find(employee => employee.Id === this.hourRegistrationForm.value.CompletedBy)?.ContactId;
  }

  validateDateWithinSevenDays(): boolean {
    const completedDateValue = this.hourRegistrationForm.value.CompletedDate;

    if (!completedDateValue) {
      alert('Ugyldig dato');
      return false;
    }

    const completedDate = new Date(completedDateValue);
    const today = new Date();
    const maxFutureDate = new Date(today);
    maxFutureDate.setDate(today.getDate() + 7);

    if (completedDate > maxFutureDate) {
      const activity = newActivity({
        message: 'Datoen er satt til mer enn 7 dager frem i tid.',
        type: "warning",
        timestamp: new Date(),
        userDismiss: true
      });

      this.als.appendActivity(activity);
      return false;
    }

    return true;
  }

  // checkMaxHoursLimit(): boolean {
  //   const hours = this.hourRegistrationForm.value.Hours || 0;
  //   const minutes = this.hourRegistrationForm.value.Minutes || 0;
  //   const totalHours = hours + (minutes / 60);

  //   if (totalHours >= 12) {
  //     const activity = newActivity({
  //       message: 'Du har registrert 12 timer eller mer på denne timeregistreringen.',
  //       type: "warning",
  //       timestamp: new Date()
  //     });

  //     this.als.appendActivity(activity);
  //     return false;
  //   }

  //   return true;
  // }

  checkMaxHoursLimit(): boolean {
    const hours = this.hourRegistrationForm.value.Hours || 0;
    const minutes = this.hourRegistrationForm.value.Minutes || 0;
    const totalHours = hours + (minutes / 60);

    if (totalHours >= 12) {
      const userConfirmed = confirm('OBS! Summen av timer og minutter er mer enn 12 timer. Ønsker du å fortsette?');
      return userConfirmed;
    }

    return true;
  }

  onSubmit() {

    if (!this.validateDateWithinSevenDays()) {
      return;
    }

    if (!this.checkMaxHoursLimit()) {
      return;
    }

    const userHasRole = this.data.getCaseContacts().some(contact => contact.ContactId.toUpperCase() === this.getUserContact()?.toUpperCase());

    if (!userHasRole) {

      this.roleDialog.open();
    } else {
      this.saveHourRegistration();
    }
  }

  onAddRoleOKSelected() {
    const selectedUserId = this.hourRegistrationForm.value.CompletedBy;

    if (!selectedUserId) {
      console.error("Contact not selected");
      this.roleDialog.close();
      return;
    };
    const selectedContact = this.employees.find(employee => employee.Id.toUpperCase() === selectedUserId.toUpperCase())?.ContactId;
    if (!selectedContact) {
      console.error("Selected contact not found");
      this.roleDialog.close();
      return;
    }

    this.addRole(selectedContact);

    this.roleDialog.close();
  }

  @Output() notifyParent = new EventEmitter<CaseBE>();

  addRole(contactId: string) {
    this.dlxClient.NewCaseContact().subscribe({
      next: (res) => {
        res.CaseId = this.data.Id;
        res.ContactId = contactId;
        res.RoleTypeName = "Andre medarbeidere";
        res.RoleTypeCode = RoleTypes.INTERN.IDI;
        res.RoleTypeId = RoleCode.IDI;
        res.RoleTypeCategoryId = RoleTypeCategoryIdEnum.INTERN;
        res.IsNew = true;
        res.IsDeleted = false;

        const _case = JSON.parse(JSON.stringify(this.data.getCase()));
        _case.CaseContacts.push(res);

        this.dlxClient.SaveCase(_case).subscribe({
          next: (response) => {
            this.data = new CaseBE(response);
            this.notifyParent.emit(this.data)

            this.saveHourRegistration();
          },
          error: () => {
            console.error("Failed to save case contact on case");
          }
        })
      },
      error: () => {
        console.error("Failed tgo fetch new case contact");
      }
    })
  }


  saveHourRegistration() {

    let completedDate = toLocalIsoString(this.hourRegistrationForm.value.CompletedDate);
    this.hourRegistrationForm.patchValue({ Description: replaceNewlinesForCSharp(this.hourRegistrationForm.value.Description) })
    const { Hours, Minutes, IsNew, ...obj } = this.hourRegistrationForm.value;  /* to remove Hours, Minutes, IsNew key/values */
    let objToSave!: IHourRegistrationBE;

    if (this.selectedHourRegistration) {
      objToSave = { ...this.selectedHourRegistration, ...obj };
    } else {
      objToSave = { ...this.NewHourRegistration, CaseId: this.data.Id, ...obj, CompletedDate: completedDate };
    }

    const isInvoiced = this.setSelectionIsInvoiced(objToSave)
    if (isInvoiced) return;

    if (objToSave.HourRegistrationTypeId === null) {
      return;
    }

    this.dlxClient.SaveHourRegistration(objToSave).subscribe({
      next: () => {
        this.getHourRegistrationsList();
        this.resetHourRegistrationform();
      }
    })
  }


  getRolePermissions(): number {
    const contactAccess = this.userRights.checkAccess(UserAreaEnum.CONTACTS).accessId !== UserRightTypeEnum.NONE;
    if (contactAccess) {
      const caseAccess = this.userRights.checkAccess(UserAreaEnum.CASE_MANAGMENT);
      switch (caseAccess.accessId) {
        case UserRightTypeEnum.FULL:
          return 2;
        case UserRightTypeEnum.OWN_CASES:
          return 2;
        case UserRightTypeEnum.LOOK:
          return 1;
        default:
          return 0;
      }
    }
    return 0;
  }


  shouldUpdateDescription = true;

  setShouldUpdateDescription(e: any) {
    if (e.value.trim() === "") {
      this.shouldUpdateDescription = true;
    } else {
      this.shouldUpdateDescription = false;
    }
  }
}

