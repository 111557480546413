import { IHourRegistrationTypeBE, IHourRegistrationTypeDescriptionBE } from "@datalex-software-as/datalex-client";


import { LangEnum } from "./LanguageEnum";

export class HourRegistrationFilterProvider
{
    constructor() {}

    public filterTypesByHourRegistrationMainGroup(mainGroups: IHourRegistrationTypeBE[], mainGroupId: string) {
        return  mainGroups.filter((item) => item.HourRegistrationMaingroupId.includes(mainGroupId));
    }

    public filterTypeByHourRegistrationTypeId(types: IHourRegistrationTypeBE[], hourTypeId: string): IHourRegistrationTypeBE {
        return  types.filter((item) => item.Id.includes(hourTypeId))[0];
    }

    public filterHourRegistrationDescriptionByLanguage(descriptions: IHourRegistrationTypeDescriptionBE[], langCode: string): IHourRegistrationTypeDescriptionBE {
        return  descriptions.filter((description) => description.CountryId.includes(langCode))[0];
    }

}
