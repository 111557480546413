<!-- <div class="hoursRegistration-container"> -->
<form class="hoursRegistration-form" [formGroup]="hourRegistrationForm" (ngSubmit)="onSubmit()"
  [ngClass]="{'with-Form': !includeShortlist || (includeShortlist && hasRightToEditHours)}">


  @if(!includeShortlist || (includeShortlist && hasRightToEditHours) ){
  <div class="div1 simple-select-wrapper">
    <label igxLabel class="simple-select-label simple-select-label--selected">Utført av</label>
    <igx-simple-combo [formControl]="hourRegistrationForm.controls.CompletedBy" class="simple-select-disable-reset"
      [data]="employees" displayDensity="compact" [displayKey]="'ContactName'" [valueKey]="'Id'" [type]="'border'"
      igxComboItem="comboItem" [filteringOptions]="filterOptions" (selectionChanging)="combo.searchTypeChanging($event)"
      (opening)="combo.focusInputAndFixedHeight($event, false)">
      <ng-template igxComboItem let-display let-key="valueKey">
        <div class="selectItem-content-wrapper">
          <span>{{ display.Shortform }}</span>
          <span>{{ display.ContactName }}</span>
        </div>
      </ng-template>
      <ng-template igxComboEmpty>
        <span class="empty-class">Ingen treff</span>
      </ng-template>
    </igx-simple-combo>
  </div>

  <div class="div2 simple-select-wrapper">
    <label igxLabel class="simple-select-label simple-select-label--selected">Type</label>
    <igx-simple-combo fInput [formControl]="hourRegistrationForm.controls.HourRegistrationTypeId"
      class="simple-select-disable-reset" [data]="hourRegistrationTypes" [groupKey]="'mainTypeName'"
      displayDensity="compact" [displayKey]="'Name'" [valueKey]="'Id'" [type]="'border'" igxComboItem="comboItem"
      [filteringOptions]="filterOptions" (selectionChanging)="combo.searchTypeChanging($event)"
      (opening)="combo.focusInputAndFixedHeight($event)" required>
      <ng-template igxComboItem let-display let-key="valueKey">
        <div class="selectItem-content-wrapper">
          <span>{{ display.Code }}</span>
          <span>{{ display.Name }}</span>
        </div>
      </ng-template>
      <ng-template igxComboEmpty>
        <span class="empty-class">Ingen treff</span>
      </ng-template>

      <ng-template igxComboHeaderItem let-display let-key="groupKey">
        <strong class="type-title">{{ display[key] }}</strong>
      </ng-template>
    </igx-simple-combo>
  </div>

  <div class="div3">
    <igx-input-group displayDensity="compact" type="border">
      <textarea igxInput name="Description" autocomplete="off" type="text" rows="3"
        style="resize: none;margin-bottom: 18px;" [formControl]="hourRegistrationForm.controls.Description"
        [value]="hourRegistrationForm.controls.Description" required [readOnly]="!hasRightToEditHours"
        (input)="setShouldUpdateDescription($event.target)"></textarea>
      <label igxLabel for="Description">Beskrivelse</label>
    </igx-input-group>
  </div>

  <div class="div4 date-picker">
    <igx-date-picker #datePicker [formControl]="hourRegistrationForm.controls.CompletedDate"
      [displayDensity]="'compact'" inputFormat="dd.MM.yyyy" type="border" todayButtonLabel="I dag"
      cancelButtonLabel="Avbryt" [readOnly]="!hasRightToEditHours">
      <label igxLabel for="CompletedDate">Dato</label>
      <igx-icon class="date-custom-suffix" igxPrefix (click)="decrementDate()">keyboard_arrow_left</igx-icon>
      <igx-icon igxSuffix (click)="datePicker.open()">today</igx-icon>
      <igx-icon class="date-custom-suffix" igxSuffix (click)="incrementDate()">keyboard_arrow_right</igx-icon>
    </igx-date-picker>
  </div>

  <div class="div5">
    <igx-input-group displayDensity="compact" type="border">
      <input igxInput class="search-input" id="Hours" name="Hours" autocomplete="off" type="number"
        [formControl]="hourRegistrationForm.controls.Hours" />
      <label igxLabel for="Hours">Timer</label>
    </igx-input-group>
  </div>
  <div class="div6">
    <igx-input-group displayDensity="compact" type="border">
      <input igxInput id="minutes" class=" search-input" name="minutes" autocomplete="off" type="number"
        [formControl]="hourRegistrationForm.controls.Minutes" />
      <label igxLabel for="minutes">Minutter</label>
    </igx-input-group>
  </div>

  <div class="div7">
    <igx-input-group displayDensity="compact" type="border">
      <input igxInput class=" search-input" name="Rate" autocomplete="off" type="number"
        [formControl]="hourRegistrationForm.controls.Rate" />
      <label igxLabel for="Rate">Sats</label>
    </igx-input-group>
  </div>
  <div class="div8">
    <igx-input-group displayDensity="compact" type="border">
      <input igxInput class=" search-input" name="fee" autocomplete="off" type="number"
        [formControl]="hourRegistrationForm.controls.Fee" />
      <label igxLabel for="fee">Honorar</label>
    </igx-input-group>
  </div>
  @if(selectionIsInvoiced === false) {
  <div class="div9 simple-select-wrapper">
    <label igxLabel class="simple-select-label simple-select-label--selected">Fakturakode</label>
    <igx-simple-combo [formControl]="hourRegistrationForm.controls.InvoiceTypeId" class="simple-select-disable-reset"
      [data]="invoiceTypes" displayDensity="compact" [displayKey]="'Name'" [valueKey]="'Id'" [type]="'border'"
      igxComboItem="comboItem" [filteringOptions]="filterOptions"
      (opening)="combo.focusInputAndFixedHeight($event, false)" (selectionChanging)="combo.searchTypeChanging($event)">
      <ng-template igxComboItem let-display let-key="valueKey">
        <div class="selectItem-content-wrapper">
          <span>{{ display.Code }}</span>
          <span>{{ display.Name }}</span>
        </div>
      </ng-template>
      <ng-template igxComboEmpty>
        <span class="empty-class">Ingen treff</span>
      </ng-template>
    </igx-simple-combo>
  </div>
  } @else {
  <div class="div9">
    <igx-input-group displayDensity="compact" type="border">
      <input igxInput class="search-input" name="invoiceCode" value="Fakturert" autocomplete="off" type="text" />
      <label igxLabel for="invoiceCode">Fakturakode</label>
    </igx-input-group>
  </div>
  }

  <div class="buttons-container">
    <ng-container *ngIf="!selectionIsInvoiced; else invoicedButtons">
      <div class="div10">
        <button
          [ngStyle]="{'background-color': (saveButtonActive && hasRightToEditHours && hourRegistrationForm.controls.CompletedBy.value) ? '#AEC965' : ''}"
          type="submit" igxButton="raised" igxButtonColor="white" igxRipple="white"
          [disabled]="!saveButtonActive || !hasRightToEditHours || !hourRegistrationForm.controls.CompletedBy.value">
          Registrer
        </button>
      </div>
      <div class="div11">
        <button [ngStyle]="{'background-color': (deleteButtonActive && hasRightToEditHours) ? '#F52525' : ''}"
          type="button" igxButton="raised" igxButtonColor="white" igxRipple="white"
          [disabled]="!deleteButtonActive || !hasRightToEditHours" (click)="onDeleteHourRegistration();">
          Slett
        </button>
      </div>
      <div class="div12">
        <button type="button" igxButton="raised" igxButtonColor="white" igxRipple="white"
          [disabled]="!cancelButtonActive" (click)="resetHourRegistrationform();">
          Avbryt
        </button>
      </div>
    </ng-container>
    <ng-template #invoicedButtons>
      <div class="div10">
        <button type="button" igxButton="raised" igxButtonColor="white" igxRipple="white"
          [disabled]="!cancelButtonActive" (click)="resetHourRegistrationform();">
          Avbryt
        </button>
      </div>
    </ng-template>
  </div>

  }

</form>




<!-- </div> -->


<igx-grid #grid igxPreventDocumentScroll [data]="hourRegistrationsList" [displayDensity]="deviceService.gridDensity"
  height="100%" [emptyGridMessage]="emptyGridMessage" emptyFilteredGridMessage="Filtrering gir ingen treff"
  [autoGenerate]="false" width="100%" [cellSelection]="'none'" [rowSelection]="'single'"
  (rowSelectionChanging)="handleRowSelection($event)" [hideRowSelectors]="true" [allowFiltering]="true">

  <igx-column *ngIf="isDesktop" width="90px" field="CompletedDate" header="Dato" [sortable]="true" [dataType]="'date'"
    [filterCellTemplate]="defaultFilterTemplate" [pipeArgs]="formatDateOptions"></igx-column>

  <igx-column *ngIf="!isMobile" width="120px" field="CompletedByName" header="Utført av" [sortable]="true"
    [dataType]="'string'" [filterCellTemplate]="defaultFilterTemplate"></igx-column>

  <igx-column *ngIf="isDesktop" width="70px" field="HourRegistrationTypeCode" header="Kode" [sortable]="true"
    [dataType]="'string'" [filterCellTemplate]="defaultFilterTemplate"></igx-column>

  <igx-column [width]=" isMobile ? '40%' : '150px'" field="HourRegistrationTypeName" header="Type" [sortable]="true"
    [dataType]="'string'" [filterCellTemplate]="defaultFilterTemplate"></igx-column>

  <igx-column *ngIf="!isMobile" field="Description" header="Beskrivelse" [sortable]="true" [dataType]="'string'"
    [filterCellTemplate]="defaultFilterTemplate">
  </igx-column>

  <igx-column [width]=" isMobile ? '20%' : '70px'" field="WorkedMinuttes" header="Tid" [sortable]="true"
    [dataType]="'number'" [filterCellTemplate]="defaultFilterTemplate" [hasSummary]="true" [summaries]="timeSummary">
    <ng-template igxCell let-cell="cell">
      <span>{{deconstructWorkedMinutes(cell.value )}}</span>
    </ng-template>
  </igx-column>

  <igx-column [width]=" isMobile ? '20%' : '90px'" field="Rate" header="Sats" [sortable]="true" [dataType]="'currency'"
    [filterCellTemplate]="defaultFilterTemplate">
    <ng-template igxCell let-cell="cell">
      <span>{{cell.value | norwegianNumberFormat}}</span>
    </ng-template>

  </igx-column>
  <igx-column [width]=" isMobile ? '20%' : '120px'" field="Fee" header="Honorar" [sortable]="true"
    [dataType]="'currency'" [filterCellTemplate]="defaultFilterTemplate" [hasSummary]="true" [summaries]="sumSummary">
    <ng-template igxCell let-cell="cell">
      <span>{{cell.value | norwegianNumberFormat}}</span>
    </ng-template>
  </igx-column>
  <igx-column *ngIf="!isMobile" width="140px" field="InvoiceTypeDescription" header="Fakturakode" [sortable]="true"
    [dataType]="'string'" [filterCellTemplate]="defaultFilterTemplate"></igx-column>
</igx-grid>
<div class="grid-footer-container">
  <dlx-grid-footer-collection>
    <dlx-grid-item-count [isMobile]="isMobile" [current]="grid.totalRowsCountAfterFilter"
      [total]="hourRegistrationsList.length" />
  </dlx-grid-footer-collection>
  @if(isDesktop){
  <dlx-grid-footer-collection>
    <div class="checkbox own-billable-hours">
      <input type="checkbox" name="own-billable-hours" id="own-billable-hours" [(ngModel)]="showOwnBillableHours"
        (change)="getHourRegistrationsList()">
      <label for="own-billable-hours">Vis egne, fakturerbare timer</label>
    </div>
    <div class="checkbox others-billable-hours">
      <input type="checkbox" name="others-billable-hours" id="others-billable-hours"
        [(ngModel)]="showOthersBillableHours" (change)="getHourRegistrationsList()">
      <label for="others-billable-hours">Vis alle (andres) fakturerbare timer</label>
    </div>
    <div class="checkbox all-non-billable">
      <input type="checkbox" name="all-non-billable" id="all-non-billable" [(ngModel)]="showAllNonBillable"
        (change)="getHourRegistrationsList()">
      <label for="all-non-billable">Vis alle ikke-fakturerbare timer</label>
    </div>
    <div class="checkbox invoiced-status">
      <input type="checkbox" name="invoiced-status" id="invoiced-status" [(ngModel)]="showInvoicedStatus"
        (change)="getHourRegistrationsList()">
      <label for="invoiced-status">Timer med fakturastatus Fakturert</label>
    </div>
  </dlx-grid-footer-collection>
  }

</div>

<ng-template #defaultFilterTemplate igxFilterCellTemplate let-column="column">
  <grid-filter-input [grid]="grid" [column]="column"></grid-filter-input>
</ng-template>


<!-- <igx-dialog #roleDialog title="Valgt medarbeider har ikke rolle på saken." leftButtonLabel="Avbryt"
  (leftButtonSelect)="roleDialog.close()" [rightButtonLabel]="rolePermission >  0 ? 'Ok': 'Ja' "
  (rightButtonSelect)="rolePermission >  0 ? roleDialog.close() : onAddRoleOKSelected()"> -->


<igx-dialog #roleDialog title="Valgt medarbeider har ikke rolle på saken.">
  <div class="flex-col flex-gap-1">
    @if(rolePermission > 0 ){
    <p>Vil du opprette en rolle for valgt medarbeider?</p>
    <p>Hvis du ikke velger å opprette en rolle for medarbeideren vil ikke timen bli registrert!</p>
    } @else {
    <p>Det kreves minst "SE" rettigheter på området "Kontakt" for å legge til rolle.</p>
    <p>Kontakt brukeradministrator for hjelp.</p>

    }

  </div>
  <div igxDialogActions>
    @if(rolePermission > 0){
    <button igxButton (click)="roleDialog.close()" igxButton="flat" igxRipple="white">Avbryt</button>
    }
    <button igxButton (click)="rolePermission >  0 ? onAddRoleOKSelected() : roleDialog.close()" igxButton="raised"
      igxButtonColor="white" igxRipple="white">{{rolePermission > 0 ? 'Ja':
      'Ok'}}</button>
  </div>

</igx-dialog>