
export enum SystemDialogEnum {
  GENERAL_DELETE_CONFIRMATION = 'Er du sikker på at du vil slette?',
  GENERAL_SAVE_CONFIRMATION = 'Er du sikker på at du vil lagre endringene?',
  ROLE_CHANGE_CONFIRMATION = 'Er du sikker på at du vil endre rollen?',
  ROLE_DELETE_CONFIRMATION = 'Er du sikker på at du vil å fjerne *full_name* som "*role_name*" fra saken?',
  DOCUMENT_DELETE_ERROR = 'Kunne ikke slette *doc_name*.',
  PROTECTED_DOCUMENT_DELETE_ERROR = 'Dokumentet er beskyttet og kan ikke slettes.',
  ACCOUNTING_DOCUMENT_DELETE_ERROR = 'Regnskapsdokumenter er beskyttet og kan ikke slettes.',
  DOCUMENT_OPEN_ERROR = 'Kunne ikke åpne dokumentet *doc_name*.',
  DOCUMENT_DOWNLOAD_ERROR = 'Kunne ikke last ned *doc_name*.',
  SPECIFIC_DELETE_CONFIRMATION = 'Er du sikker på at du vil slette "*doc_name*"?',
  DELETE_USER_CONFIRMATION = 'Er du sikker på at du vil slette brukeren *username*?',
  REMOVE_ITEM_CONFIRMATION = 'Er du sikker på at du vil fjerne *item_name* fra listen?',
  LOGOUT_CONFIRMATION = 'Er du sikker på at du vil logge ut?',
  DISCARD_CHANGES_CONFIRMATION = 'Er du sikker på at du vil forkaste endringene?',
  UNSAVED_CHANGES_CONFIRMATION = 'Du har ulagrede endringer. Er du sikker på at du vil fortsette?',
}
