import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'dlx-grid-footer-collection',
  standalone: true,
  imports: [CommonModule],
  template: `<ng-content></ng-content>`,
  styles: [`
    :host {
      display: flex;
    }
  `]
})
export class GridFooterCollectionComponent {

}
