// export const formatNumberWithThousands = (x: number) => {
//   return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace(".", ",");
// }

export const formatNumberWithThousands = (
  value: number | null,
  locale: string = 'no-NO',
  minimumFractionDigits: number = 2,
  maximumFractionDigits: number = 2
): string => {
  if (value === null) return '';

  return new Intl.NumberFormat(locale, {
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(value);
};

